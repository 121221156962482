<template>
  <div>
    <v-sheet class="header-backup"></v-sheet>
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm pr-0"
      flat
      height="60"
    >
      <v-app-bar-nav-icon
        v-ripple="{ class: 'primary--text' }"
        @click="toggleVerticalSidebarDrawer"
      />
      <v-img src="@/assets/images/logos/PositiveMediaWideBlue.png" class="flex-grow-0" width="198" height="31" contain/>
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />
      <v-spacer />
      <v-alert class="mb-0 text-capitalize mr-5" v-if="campaignName && titleRoute.indexOf($route.name) !== -1"
        dense
        text
        color="primary"
      >
        Campaign: <b>{{ campaignName }}</b>
      </v-alert>
      <v-alert class="mb-0 text-capitalize" v-if="donorName && titleRoute.indexOf($route.name)!==-1"
        dense
        text
        color="primary"
      >
        You're speaking with <b>{{ donorName }}</b>
      </v-alert>
      <v-spacer />
      <v-chip
        pill
        class="transparent rounded-pill py-6"
        @click="userDrawer = !userDrawer"
      >
        Hi, {{ user && user.name ? user.name : '' }}
        <v-avatar class="ml-2">
          <v-img src="@/assets/images/faces/1.jpg"></v-img>
        </v-avatar>
      </v-chip>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            @click.native="logoutUser"
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
          />
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HeaderLayout",
  components: {
    UserDrawer: () => import("@/layouts/common-drawer/UserDrawer"),
  },
  computed: {
    ...mapGetters(["getThemeMode", "currentStep", "model"]),

    campaignName() {
       return this.donorName ? this.model.sf_campaign_name : null
    }
  },
  data() {
    return {
      user: null,
      userDrawer: false,
      searchDrawer: false,
      donorName: null,
      titleRoute: ['LP Entry', 'Home']
    };
  },
  watch: {
    currentStep(newVal) {
      this.donorName = newVal >= 2 && this.model && (this.model.first_name || this.model.last_name) ? `${this.model.first_name} ${this.model.last_name}` : null;
    }
  },
  created () {
    this.user = this.$helpers.getCurData('curUser');
    this.donorName = this.currentStep >= 2 && this.model && (this.model.first_name || this.model.last_name) ? `${this.model.first_name} ${this.model.last_name}` : null;
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      "changeVerticalSidebarMini",
      "signOut",
    ]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();
    },
    logoutUser() {
      this.$helpers.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
.header-backup {
  display: block;
  width: 100%;
  height: 'auto';
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
